import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
// import { LoginComponent } from './login/login.component';
import {MatTableModule} from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { HeaderComponent } from './common/header/header.component';
// import { FooterComponent } from './common/footer/footer.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSortModule } from '@angular/material/sort';
import { HttpService } from './shared/http.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import {NgxUiLoaderModule, NgxUiLoaderService} from "ngx-ui-loader";
import {MatPaginatorModule} from '@angular/material/paginator';
import { ViewRecordsComponent } from './view-records/view-records.component';

// import { ContactUsComponent } from './contact-us/contact-us.component';
// import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
// import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
// import { CreateRecordComponent } from './create-record/create-record.component';
// import { RecordsListComponent } from './records-list/records-list.component';

@NgModule({
  declarations: [
    AppComponent,
    
    // ContactUsComponent,
    // TermsConditionsComponent,
    // PrivacyPolicyComponent,
    // CreateRecordComponent,
    // RecordsListComponent,
    // HeaderComponent,
    // FooterComponent,
    // DashboardComponent,
    // LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    NgxUiLoaderModule,
    MatSortModule,
    BrowserAnimationsModule,
    MatSnackBarModule,

    MatPaginatorModule,
    
    ToastrModule.forRoot(),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpService, multi: true },NgxUiLoaderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
