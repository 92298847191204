import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from  "@angular/router";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpResponse  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry, tap, finalize } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarMessage, LoginReq } from "./../models/model";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  
  SERVER_URL = environment.SERVER_URL;
  sideBarHeight: number = 0;
  page:any
  profile:any
  constructor(private loader:NgxUiLoaderService,private route:ActivatedRoute,private router:Router,private http:HttpClient,private _snackBar: MatSnackBar) {
    
    // alert(env.production)
  }

  getToken(){

    if(localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes"){
      
      return localStorage.getItem("token")

    } if(localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "no") {
      
      return sessionStorage.getItem("token")

    }else{

      return null
      
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    this.loader.start();
    let ok: string;
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.getToken()}`
      }
    });
    return next.handle(request).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => ok = event instanceof HttpResponse ? 'succeeded' : '',
        // Operation failed; error is an HttpErrorResponse
        error => ok = error
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        
        this.loader.stop();
        const elapsed = Date.now() - started;
        if (ok == "succeeded") {
          const msg = `${request.method} "${request.urlWithParams}"
           ${ok} in ${elapsed} ms.`;
          console.log(msg);
        } else {
          if (typeof ok == "object" && ok["status"] == 401) {
            // logout from here
            localStorage.clear()
            sessionStorage.clear()
            this.router.navigate(['login']);
            console.log(ok["status"]);
          } else {
            this.handleError(ok)
          }
        }

      })
    );
  }


  isAuthenticated(){

    if(localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes"){     
      
      return localStorage.getItem('isLogged') == "true"

    } if(localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "no") {
      
      return localStorage.getItem('isLogged') == "true"

    }else{

      return false
      
    }
  }


  loggedUserDetails(){

    // if(localStorage.getItem("user_details")){
    //   return JSON.parse(localStorage.getItem("user_details"))
    // }else{
    //   return null
    // }


    if(localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes"){

      return JSON.parse(localStorage.getItem("user_details") || '{}')  

    } if(localStorage.getItem("remember_me")){

      return JSON.parse(sessionStorage.getItem("user_details") || '{}')

    } else {

      return null

    }

  }

  setLoggedUserDetails(object:any){   

    if(localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes"){

      localStorage.setItem("user_details",JSON.stringify(object))

    } if(localStorage.getItem("remember_me")){


      sessionStorage.setItem("user_details",JSON.stringify(object))

    } else {

    }

  }

  showSuccessMessage(object:SnackBarMessage){

    this._snackBar.open(object.message, object.action ? object.action : "CLOSE", {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });

  }
  showErrorMessage(object:SnackBarMessage){

    this._snackBar.open(object.message, object.action ? object.action : "CLOSE", {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });

  }
  
  handleError(error:any) {
    let errorMessage = '';
    if (error.error) {
      // client-side error and server side
      if(Array.isArray(error.error.errors.msg)){ // validation error message
        
        if(error.error.errors.msg.length){
          const ob = error.error.errors.msg[0]
          if(ob.msg == "IS_EMPTY"){
            errorMessage = `${ob.param} missing`
          }else{
            errorMessage = "Parameters missing"

          }
        }
        
      }else{
        errorMessage = error.error.errors.msg;
      }
    } else {
      // server-side error
      errorMessage = `${error.message}`;
    }
    this._snackBar.open(errorMessage,"CLOSE", {
      duration: 2000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });
    return throwError(errorMessage);
  }

  login(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/login`;
    console.log(API_URL)
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  test(data:LoginReq): Observable<any> {
    let API_URL = `${this.SERVER_URL}gfhfghf`;
    console.log(API_URL)
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        })
      )
  }
  forgotpass(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/forgotemail`;
    console.log(API_URL)
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res
        })
      )
  }
    getrecordlist(): Observable<any> {
     let API_URL = `${this.SERVER_URL}/users/categorylist`;
    console.log(API_URL)
     return this.http.get(API_URL)
      .pipe(
        map(res => {
           return res
         })
       )
   }
   getCategoryDetails(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/categoryQuestions/${data.id}`;
   console.log(API_URL)
    return this.http.get(API_URL)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  saveDetails(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/addcategoryque`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  AddRecord(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/submit/question/answer`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  ViewCms(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/get/cms`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  viewAllRecords(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/records`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  viewRecordDetails(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/getrecord`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  editExistingRecords(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/editcategoryque`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  editRecord(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/editrecord`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  submitContactUs(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/addcontact`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  getActivities(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/getactivities`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  getReviewers(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/getreviewmanagement`;
   console.log(API_URL)
    return this.http.get(API_URL)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  getContactUs(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/getcontactinfo`;
   console.log(API_URL)
    return this.http.get(API_URL)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  getkpi(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/kpidetails`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  // getkpiid(data:any): Observable<any> {
  //   let API_URL = `${this.SERVER_URL}/users/getkpidetails`;
  //  console.log(API_URL)
  //   return this.http.post(API_URL,data)
  //    .pipe(
  //      map(res => {
  //         return res
  //       })
  //     )
  // }
  cancelrecord(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/cancelrecord`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  clientrisk(data:any): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/consultantgraph`;
   console.log(API_URL)
    return this.http.post(API_URL,data)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  consultantlist(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/consultantlist`;
   console.log(API_URL)
    return this.http.get(API_URL)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  getconsultantlist(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/clientconsultants`;
   console.log(API_URL)
    return this.http.get(API_URL)
     .pipe(
       map(res => {
          return res
        })
      )
  }
  getpredefined(): Observable<any> {
    let API_URL = `${this.SERVER_URL}/users/predefined/questions`;
   console.log(API_URL)
    return this.http.get(API_URL)
     .pipe(
       map(res => {
          return res
        })
      )
  }
}
  