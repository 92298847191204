import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [

	
	{
		path: '',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
	},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
	},
	
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
		canActivate:[AuthGuard]
	},
	{
		path: 'records-list',
		loadChildren: () => import('./records-list/records-list.module').then(m => m.RecordsListModule),
		canActivate:[AuthGuard]
	},
	{
		path: 'create-record',
		loadChildren: () => import('./create-record/create-record.module').then(m => m.CreateRecordModule),
		canActivate:[AuthGuard]
	},
	{
		path: 'view-record',
		loadChildren: () => import('./view-records/view-records.module').then(m => m.ViewRecordModule),
		canActivate:[AuthGuard]
	},
	{
		path: 'privacy-policy',
		loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
		canActivate:[AuthGuard]
	},
	{
		path: 'terms-conditions',
		loadChildren: () => import('./terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule),
		canActivate:[AuthGuard]
	},
	{
		path: 'contact-us',
		loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule),
		canActivate:[AuthGuard]
	},
	{
		path:'forgot-password',
		loadChildren:()=> import('./forgot-password/forgot-password.module').then(m=>m.ForgotPasswordModule)
		
	},
	{
		path:'kpi',
		loadChildren:()=> import('./kpi/kpi.module').then(m=>m.KpiModule),
		canActivate:[AuthGuard]
	},

	
	{ path: 'clientRisk', loadChildren: () => import('./client-risk/client-risk.module').then(m => m.ClientRiskModule) }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
