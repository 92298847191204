import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from './shared/http.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
  constructor( private router: Router,private service : HttpService) {}
  canActivate(){
    // alert(this.service.getToken())
    if(this.service.getToken()) { 

      console.log('token',this.service.getToken());
      
      // this.router.navigate(['/dashboard'])
      return true;
    }
      this.router.navigate(['/login']);
      return false;
  }
  
}
